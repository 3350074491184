<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Countries</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="openModal">Add Country</b-button>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-table bordered hover :items="rows" :fields="columns">
                  <template v-slot:cell(action)="data">
                    <div class="text-center">
                      <b-button variant="info" size="sm" @click="edit(data.item)">
            <i class="fas fa-pencil-alt"></i> <!-- Edit font icon -->
          </b-button>
          <b-button variant="danger" size="sm" @click="remove(data.item.public_id)">
            <i class="fas fa-trash-alt"></i> <!-- Delete font icon -->
          </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- Add/Edit Modal -->
    <b-modal v-model="modalOpen" :title="editMode ? 'Edit Country' : 'Add New Country'" ok-title="Save" @ok="submitForm">
      <b-form @submit.stop.prevent="submitForm">
        <b-form-group label="Name" label-for="nameInput">
          <b-form-input id="nameInput" v-model="newCity.name" required></b-form-input>
        </b-form-group>
        <b-form-group label="City" label-for="cityInput">
          <b-form-input id="cityInput" v-model="newCity.city" required></b-form-input>
        </b-form-group>
        <b-form-group label="Code" label-for="codeInput">
          <b-form-input id="codeInput" v-model="newCity.code" required></b-form-input>
        </b-form-group>
        <b-form-group label="Sensor IP" label-for="sensorIpInput">
          <b-form-input id="sensorIpInput" v-model="newCity.sensor_ip" required></b-form-input>
        </b-form-group>
        <b-form-group label="Type" label-for="typeIpInput">
          <b-form-select id="typeIpInput" v-model="newCity.type" :options="types" required></b-form-select>
        </b-form-group>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import { core } from '../../config/pluginInit'
import axios from 'axios'
import constant from '@/config/constant';
import CryptoJS from 'crypto-js';
export default {
  name: 'UiDataTable',
  data() {
    return {
      columns: [
        { label: 'Name', key: 'name', class: 'text-left' },
        { label: 'City', key: 'city', class: 'text-left' },
        { label: 'Code', key: 'code', class: 'text-left' },
        { label: 'Type', key: 'type', class: 'text-left' },
        { label: 'Sensor Ip', key: 'sensor_ip', class: 'text-left' },
        { label: 'Action', key: 'action', class: 'text-center' }
      ],
      rows: [], // Initialize the rows array to be empty initially
      modalOpen: false,
      editMode: false,
      newCity: {
        name: '',
        city: '',
        sensor_ip: '',
        type: '',
        code: ''
      },
      types: [
        { text: 'Http', value: 'Http' },
        { text: 'Https', value: 'Https' }
      ] // Initialize the sensor IPs array
    }
  },
  methods: {
    decryptToken(encryptedToken, key) {
    const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
    const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedToken;
},
async fetchData() {
  try {
    const token = localStorage.getItem('token');
    const key = 'enc'; // Fetch encrypted token
    const decryptedToken = this.decryptToken(token, key); // Decrypt token

    const response = await axios.get(constant.API_BASE_URL + 'domain/countries/', {
      headers: {
        'Authorization': 'Token ' + decryptedToken
      }
    });
    
    this.rows = response.data.results;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
},
    async submitForm() {
      try {
        const token = localStorage.getItem('token');
        const key = 'enc'; // Fetch encrypted token
        
        const decryptedToken = this.decryptToken(token,key); // Decrypt token
        alert(decryptedToken)
        const { name, code, city, sensor_ip, type } = this.newCity;

        const requestData = {
            name: name,
            code: code,
            city: city,
            type: type,
            sensor_ip: sensor_ip
        };

        // Set the token in the request headers
        const headers = {
          'Authorization': 'Token ' + decryptedToken
        };

        let response;
        if (this.editMode) {
            // Edit mode
            response = await axios.put(constant.API_BASE_URL + 'domain/countries/' + this.newCity.public_id + '/', requestData, { headers });
        } else {
            // Add mode
            response = await axios.post(constant.API_BASE_URL + 'domain/countries/', requestData, { headers });
        }

        await this.fetchData();
        this.modalOpen = false;
        this.clearForm();
      } catch (error) {
          console.error('Error adding/editing city:', error);
      }
    },
    openModal() {
      this.modalOpen = true;
      this.editMode = false;
      this.clearForm();
    },
    edit(item) {
      this.newCity = { ...item };
      this.modalOpen = true;
      this.editMode = true;
    },
    async remove(public_id) {
      try {
        const token = localStorage.getItem('token');
    const key = 'enc';
    const decryptedToken = this.decryptToken(token, key);
        await axios.delete(constant.API_BASE_URL + 'domain/countries/' + public_id + '/', {
      headers: {
        'Authorization': 'Token ' + decryptedToken
      }
    });
        await this.fetchData();
      } catch (error) {
        console.error('Error deleting city:', error);
      }
    },
    clearForm() {
      this.newCity = {
        name: '',
        city: '',
        sensor_ip: '',
        type: '',
        code: ''
      };
    }
  },
  mounted() {
    this.fetchData();
    core.index();
  }
}
</script>
